import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";

// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, product, title, ...other }, ref) => (
    <>
      <Helmet>
        <title>{title}</title>

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={product?.title || "Campanha"}
        />
        <meta
          property="og:description"
          content={
            product?.description || "Descrição da campanha"
          }
        />
        <meta
          property="og:image"
          content={product?.imageUrls[0] || ""}
        />
      </Helmet>

      <Box ref={ref} {...other} sx={{ mb: "-30px " }}>
        {children}
      </Box>
    </>
  )
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  product: PropTypes.object,
};

export default Page;
