import { concat, isNaN } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  // console.log('aquioh', number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  // return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
  return concat("R$ ", number / 100);
  // return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  // console.log(numeral(number).format(''));
  return numeral(number).format();
}

export function fNumberDecimals(value) {
  value = String(value);
  // Remove caracteres não-numéricos exceto ponto decimal
  value = value.replace(/[^0-9.]/g, "");

  // Formatar para milhares com até duas casas decimais
  const parts = value.split(".");
  if (parts.length > 1) {
    parts[0] = parts[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );
    parts[1] = parts[1].substring(0, 2);
    value = parts.join(".");
  } else {
    value = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return value;
}

export function fNumberReal(value) {
  // Remover qualquer caractere que não seja número, ponto ou vírgula
  const valorLimpo = value.replace(/[^\d.,]/g, "");

  // Substituir vírgulas por pontos para tratar separador decimal
  const valorNumerico = parseFloat(
    valorLimpo.replace(",", ".")
  );

  if (isNaN(valorNumerico)) {
    return "Valor inválido";
  }

  // Formatar como moeda brasileira
  const formatoBrasileiro = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatoBrasileiro.format(valorNumerico);
}

export function fShortenNumber(number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}
