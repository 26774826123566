// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  // INDIGO
  {
    name: 'indigo',
    lighter: '#D1D9FF',
    light: '#768FFF',
    main: '#3D5AFE',
    dark: '#0022FF',
    darker: '#0012CB',
    contrastText: '#fff',
  },
  // TEAL
  {
    name: 'teal',
    lighter: '#D9FFFB',
    light: '#84E0E0',
    main: '#00B3B3',
    dark: '#007575',
    darker: '#004242',
    contrastText: '#fff',
  },
  // LIME
  {
    name: 'lime',
    lighter: '#F4FF81',
    light: '#EEFF41',
    main: '#C6FF00',
    dark: '#AEEA00',
    darker: '#7CCB00',
    contrastText: palette.light.grey[800],
  },
  // YELLOW
  {
    name: 'yellow',
    lighter: '#FFFF8D',
    light: '#FFFF00',
    main: '#FFEA00',
    dark: '#FFD600',
    darker: '#FFC400',
    contrastText: palette.light.grey[800],
  },
  // PINK
  {
    name: 'pink',
    lighter: '#FFD1E7',
    light: '#FF8BBD',
    main: '#FF4081',
    dark: '#F50057',
    darker: '#AB003C',
    contrastText: '#fff',
  },
  // BROWN
  {
    name: 'brown',
    lighter: '#D7CCC8',
    light: '#BCAAA4',
    main: '#795548',
    dark: '#543B32',
    darker: '#3E2723',
    contrastText: '#fff',
  },
  // GREY
  {
    name: 'grey',
    lighter: '#F5F5F5',
    light: '#EEEEEE',
    main: '#9E9E9E',
    dark: '#616161',
    darker: '#424242',
    contrastText: '#fff',
  },
  // DEEP ORANGE
  {
    name: 'deepOrange',
    lighter: '#FFD0B0',
    light: '#FFAB91',
    main: '#FF5722',
    dark: '#C41C00',
    darker: '#870000',
    contrastText: '#fff',
  },
  // DEEP PURPLE
  {
    name: 'deepPurple',
    lighter: '#D1C4E9',
    light: '#B39DDB',
    main: '#673AB7',
    dark: '#320B86',
    darker: '#17002E',
    contrastText: '#fff',
  },
  // BLUE GREY
  {
    name: 'blueGrey',
    lighter: '#CFD8DC',
    light: '#B0BEC5',
    main: '#607D8B',
    dark: '#34515E',
    darker: '#102027',
    contrastText: '#fff',
  },
  // AMBER
  {
    name: 'amber',
    lighter: '#FFECB3',
    light: '#FFE082',
    main: '#FFC107',
    dark: '#FFA000',
    darker: '#FF8F00',
    contrastText: palette.light.grey[800],
  },
  // LIGHT BLUE
  {
    name: 'lightBlue',
    lighter: '#B3E5FC',
    light: '#81D4FA',
    main: '#03A9F4',
    dark: '#007AC1',
    darker: '#004C8C',
    contrastText: '#fff',
  }

];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];
export const indigoPreset = colorPresets[6];
export const tealPreset = colorPresets[7];
export const limePreset = colorPresets[8];
export const yellowPreset = colorPresets[9];
export const pinkPreset = colorPresets[10];
export const brownPreset = colorPresets[11];
export const greyPreset = colorPresets[12];
export const deepOrangePreset = colorPresets[13];
export const deepPurplePreset = colorPresets[14];
export const blueGreyPreset = colorPresets[15];
export const amberPreset = colorPresets[16];
export const lightBluePreset = colorPresets[17];


// ----------------------------------------------------------------------



export default function getColorPresets(presetsKey) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    indigo: indigoPreset,
    teal: tealPreset,
    lime: limePreset,
    yellow: yellowPreset,
    pink: pinkPreset,
    brown: brownPreset,
    grey: greyPreset,
    deepOrange: deepOrangePreset,
    deepPurple: deepPurplePreset,
    blueGrey: blueGreyPreset,
    amber: amberPreset,
    lightBlue: lightBluePreset,
    default: defaultPreset,
  }[presetsKey];
}
